import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import ViewMenu from './ViewMenu'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, mobileHero } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="95VH"
          w="100%"
          image={`url(${mobileHero}) center / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView
            textCenter
            column
            center
            w="100%"
            bg="rgb(0,0,0,.8)"
            boxShadow="0 2px 5px rgba(0,0,0,.5)"
          >
            <CFView w="80%" pulsate mt="10px" mb="6px">
              <OrderPickupButton />
            </CFView>
            {/* <CFView w="80%" mt="10px" mb="6px">
              <ViewMenu />
            </CFView> */}
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="680px"
          w="100%"
          image={`url(${hero}) center / cover no-repeat`}
          zIndex={90}
          column
          justifyBetween
          alignCenter
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
        >
          <Header />
          <CFView
            column
            center
            alignCenter
            w="100%"
            bg="rgb(0,0,0,.8)"
            pt="4px"
          >
            <CFView column justifyStart>
              <CFText color="white" style={{ fontSize: 32 }} raleway bold>
                NOW TAKING ONLINE ORDERS
              </CFText>
            </CFView>
            <CFView row center>
              <CFView mt="8px" pulsate>
                <OrderPickupButton />
              </CFView>
              {/* <CFView mt="8px" ml="20px">
                <ViewMenu />
              </CFView> */}
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
